import React, { useEffect, useState } from "react";
import LeadInfoForm from "../LeadMainInfo/LeadInfoForm";
import getAvailableSpots from "../../../api/getAvailableSpots";
import { modifySpots } from "utils/modifySpots";

interface SchedulingInfoProps {
  closer?: boolean;
  setData: React.Dispatch<any>;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  data: any;
  errors: string[];
  eventId?: string;
  isCancelApt?: boolean,
  step?: number,
  cancelApt?: any,
  onSlotSelect: (isSelected: boolean, date: any, slot: string, addition: any) => void;
  selectedDate: any;
  selectedSlot: string;
}

export const groupBy = (items: any[], key: string) =>
  items?.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

const getFormFields = (disabled: boolean, spots?: any) => [    
  {
    label: "Select a date and time",
    component: "dateInput",
    name: "date",
    additionalClassName: "dateInput",
    options: spots || [],
  },
];

const SchedulingInfo = (props: SchedulingInfoProps): React.JSX.Element => {
  const [formFields, setFormFields] = useState(getFormFields(true));
  const [optionsForSelect, setOptionForSelect] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [loadingSpots, setLoadingSpots] = useState(false);

  useEffect(() => {
    props.setData((prev: any) => ({
      ...prev,
      slot: "",
      isAroSpot: null,
      spotID: null,
    }));
    
    setFormFields(getFormFields(!props.data.date?.length, optionsForSelect));
  }, [props.data.date, optionsForSelect]);

  useEffect(() => {
    if (props.data.slot) {
      const selectedDate = optionsForSelect.find(o => o.value === props.data.date);
      const appointmentInfo = selectedDate?.additions.find((a: { slot: any; }) => a.slot === props.data.slot);
      if (appointmentInfo) {
        props.setData((prev: any) => ({ 
          ...prev, 
          spotID: appointmentInfo.spotID,
          isAroSpot: appointmentInfo.isAroSpot,
          isAnytime: appointmentInfo.isAnytime
        }));
      }
    } else {
      props.setData((prev: any) => ({
        ...prev,
        isAroSpot: null,
        spotID: null,
      }));
    }
  }, [props.data.slot, optionsForSelect]);

  useEffect(() => {
    (async () => {
      setLoadingSpots(true);
      const res = await getAvailableSpots(page, props.eventId);
      
      if(res.data !== undefined) {
        const dates = res.data.dates;
        const spots = res.data.spots;
        const modifiedSpots = modifySpots(dates, spots);
        
        const spotsForSelect = Object.entries(modifiedSpots).map(([key, value]) => ({
          label: key,
          value: key,
          additions: value,
        }));

        setOptionForSelect(spotsForSelect);
        setFormFields(getFormFields(true, spotsForSelect));
        setLoadingSpots(false);
      } 
    })();
  }, [page, props.data?.appointment]);

  return (
    <LeadInfoForm
      {...props}
      formFields={formFields}
      page={page}
      setPage={setPage}
      loadingSpots={loadingSpots}
      eventId={props.eventId}
      setData={props.setData}
      data={props.data}
      onSlotSelect={props.onSlotSelect}
      selectedDate={props.selectedDate}
      selectedSlot={props.selectedSlot}
    />
  );
};

export default SchedulingInfo;