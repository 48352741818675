import React, { useState, useEffect } from "react";

import { CreateLeadStyles } from "./CreateLead.Styles";
import Modal from "./../Modal/Modal";
import IconButton from "./../Buttons/IconButton";
import PhoneNumberInput from "./../Form/Input/PhoneNumberInput";
import createLead from "./../../api/createLead";

const CreateLead = (): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");

  const create = async () => {
    try {
      // Strip out masking
      const unmaskedPhoneNum = phoneNum.replace(/\D/g, "");
      if (unmaskedPhoneNum.length === 10) {
        const resp = await createLead(unmaskedPhoneNum);
        window.location.replace(`/${resp.data.event_id}`);
        setIsOpen(false);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    setPhoneNum("");
  }, [isOpen]);

  useEffect(() => {
    const unmaskedPhoneNum = phoneNum.replace(/\D/g, "");
    if (unmaskedPhoneNum.length === 10) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [phoneNum]);

  return (
    <CreateLeadStyles data-testid="create-lead-form">
      <IconButton
        onClick={() => setIsOpen(true)}
        icon={"Plus"}
        type={"button"}
        tooltip="Create New Lead"
      />
      {isOpen && (
        <Modal
          header={"Create New Lead"}
          body={
            <PhoneNumberInput
              value={phoneNum}
              onChange={setPhoneNum}
              placeholder={"e.g., 555-867-5309"}
            />
          }
          actionName={"Create"}
          withCancel
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          disableAction={!isValid}
          action={() => create()}
        />
      )}
    </CreateLeadStyles>
  );
};

export default CreateLead;
