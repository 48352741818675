import { Loader } from "@googlemaps/js-api-loader";

import { GOOGLE_API_KEY } from "../../../constants";
import { updatePlaceData } from "./autoComplete";

let autoComplete: any = null;
let props: any = null;

const handlePlaceSelect = () => {
  // Get the place details from the autocomplete object.
  const place = autoComplete.getPlace();

  const placeData = updatePlaceData(place);

  props.setData((prev: any) => ({
    ...prev,
    ["province"]: placeData.province,
    ["city"]: placeData.city,
    ["postal_code"]: placeData.postcode,
    ["address_line_1"]: placeData.address,
    ["county"]: placeData.county,
    ["latitude"]: place.geometry.location.lat(),
    ["longitude"]: place.geometry.location.lng(),    
  }));
};

const addAutocomplete = (google: any) => {
  const addressInput = document.getElementById(
    "address_line_1",
  ) as HTMLInputElement;

  autoComplete = new google.maps.places.Autocomplete(addressInput, {
    componentRestrictions: { country: ["us"] },
  });
  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autoComplete.addListener("place_changed", handlePlaceSelect);
};

export const loadAutoComplete = (data: any) => {
  props = data;
  // Google maps loader for address
  const loader = new Loader({
    apiKey: GOOGLE_API_KEY,
    version: "weekly",
    libraries: ["places"],
  });

  loader
    .load()
    .then((google) => {
      addAutocomplete(google);
    })
    .catch((e) => {
      console.log(e);
    });
};
