import { API_URL } from "constants";
import { apiRequest } from "./apiRequest";

export const getUrl = (eventId) => `${API_URL}/api/v1/crm/spot/reschedule?id=${eventId}`;

export const rescheduling = async (eventId, scheduleInfo) => {
    const url = getUrl(eventId);
    const body = {...scheduleInfo};
    return await apiRequest(url, "post", JSON.stringify(body));
};
