import { API_URL } from "../constants";
import { apiRequest } from "./apiRequest";

export const getUrl = (zip, systemId, contactId) => `${API_URL}/api/v1/serviceable/${zip}?event_id=${systemId}&contact_id=${contactId}`;

const getServicable = async (zip, systemId, contactId) => {
  return await apiRequest(getUrl(zip, systemId, contactId), "get");
};

export default getServicable;
