export const updatePlaceData = (place: any) => {
  let address = "";
  let postcode = "";
  let province = "";
  let city = "";
  let county = "";

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  if(place.address_components) {
    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];
  
      switch (componentType) {
        case "street_number": {
          address = `${component.long_name} ${address}`;
          break;
        }
  
        case "route": {
          address += component.short_name;
          break;
        }
  
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
  
        /*case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }*/
  
        case "locality":
          city = component.long_name;
          break;
  
        case "administrative_area_level_1": {
          province = component.short_name;
          break;
        }
  
        case "administrative_area_level_2": {
          county = component.short_name;
          break;
        }
            
      }
    }
  }
  

  return {
    county,
    province,
    city,
    postcode,
    address,
  };
};
