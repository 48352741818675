import React, { useEffect } from "react";

import { TextStyled } from "./../../components/Text/Text.Styles";

import {
  ModalStyles,
  ModalWrapper,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCross,
} from "./Modal.Styles";
import {
  ButtonSecondary,
  ButtonPrimary,
} from "components/Buttons/Button.Styled";

interface ModalProps {
  header: string;
  body: JSX.Element | string;
  actionName?: string;
  withCancel?: boolean;
  disableAction?: boolean;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  action?: () => void;
  className?: string;
}

const Modal = (props: ModalProps): React.JSX.Element => {
  const onClick = () => {
    props.action && props.action();
  };

  useEffect(() => {
    props.setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        props.setIsOpen(false);
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return props.isOpen ? (
    <ModalWrapper data-testid="modal" className={props.className || ""}>
      <ModalStyles>
        <ModalHeader>
          <TextStyled fontSize="lg">{props.header}</TextStyled>
          <ModalCross className="modal__cross" onClick={() => props.setIsOpen(false)} />
        </ModalHeader>
        <ModalBody>
          <div>{props.body}</div>
        </ModalBody>
        {props.actionName && (
          <ModalFooter>
            {props.withCancel ? (
              <ButtonSecondary onClick={() => props.setIsOpen(false)}>
                Cancel
              </ButtonSecondary>
            ) : null}
            <ButtonPrimary disabled={props.disableAction} onClick={onClick}>
              {props.actionName}
            </ButtonPrimary>
          </ModalFooter>
        )}
      </ModalStyles>
    </ModalWrapper>
  ) : (
    <></>
  );
};

export default Modal;
