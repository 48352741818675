import axios from "axios";

export const apiRequest = async (url, type, data) => {
  const oktaToken = localStorage.getItem("okta-token-storage") || "{}";
  const accessToken = JSON.parse(oktaToken).accessToken.accessToken;

  return await axios({
    headers: {
      "X-JWToken": accessToken,
      "Content-Type": "application/json",
    },
    url,
    method: type,
    data: data,
  });
};
