import styled from "@emotion/styled";
import { baseGray200 } from "styleConstants";

export const ModalStyles = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  background: white;
  border-radius: 6px;
  border: 1px solid ${baseGray200};
`;

export const ModalWrapper = styled.div`
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid ${baseGray200};
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

export const ModalCross = styled.div`
  background: url("../images/Cross.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  border-top: 1px solid ${baseGray200};
  padding: 10px 20px;
  display: flex;
  justify-content: end;

  button {
    margin-left: 16px;
  }
`;

export const ModalBody = styled.div`
  padding: 20px;
  display: flex;
`;
