import styled from "@emotion/styled";
import { bluePrimary } from "styleConstants";

export const CheckboxStyled = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #f9fafb;
    border-radius: 4px;
    border: 1px solid #e5e7eb;
  }

  input:checked ~ .checkmark {
    background-color: ${bluePrimary};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    content: url("../images/checkboxMark.svg");
    
    top: -6px;
    width: 16px;
    height: 16px;
  }
`;
