import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { NotificationStyles } from "./Notification.Styles";

import { TextStyled } from "./../Text/Text.Styles";
import { placeholderColor, uiTextError } from "styleConstants";

interface NotificationProps {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  error: boolean;
  timeout: boolean;
}

const Notification = (props: NotificationProps): React.JSX.Element => {
  const nodeRef = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.text && props.setText) {
      setShow(true);
      // Closes notification after 3 seconds
      if (props.timeout) {
        setTimeout(() => {
          setShow(false);

          // Sets text to empty after transitions have completed
          // This is to ensure a notificaiton with the same text will display
          setTimeout(() => {
            setShow(false);
            props.setText("");
          }, 500);
        }, 3500);
      }
    }
  }, [props]);

  return (
    <NotificationStyles id="notification-container" data-testid="notification-container">
      <CSSTransition
        classNames={"notification"}
        in={show}
        nodeRef={nodeRef}
        timeout={1000}
      >
        <TextStyled ref={nodeRef} color={props.error ? uiTextError : placeholderColor}>
          {props.text}
        </TextStyled>
      </CSSTransition>
    </NotificationStyles>
  );
};

export default Notification;
