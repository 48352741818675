import styled from "@emotion/styled";
import { graceGray900, baseWhite } from "styleConstants";

export const RadioInputStyled = styled.div`
  position: relative;
  background: ${baseWhite};

  .radio {
    &__field {
      display: flex;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: ${graceGray900};
      transition: all ease-in-out 0.2s;
      position: relative;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 30px);
      overflow: hidden;
      white-space: nowrap;
      background: ${baseWhite};
      border-radius: 8px 8px 0px 0px;
    }
    
    &__label {
      margin-left: 8px;
      margin-right: 16px;
    }
  }
`;
