import styled from "@emotion/styled";

interface ContainerProps {
  column?: boolean;
  alignItems?: string;
  justifyContent?: string;
  gap?: number;
}

export const FlexContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  gap: ${(props) => (props.gap ? `${props.gap}px` : "")};
`;
