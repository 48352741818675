import styled from "@emotion/styled";
import { baseWhite, graceGray900 } from "./../../styleConstants";

export const UnauthorizedStyles = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  height: fit-content;
  background: ${baseWhite};
`;

export const UnauthorizedMessage = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: ${graceGray900};
`;
