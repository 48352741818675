import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

import {
  ADMIN_ROLE,
  CLOUD_SUPERVISOR
} from "./../constants";

import Spinner from "./../components/Spinner/Spinner";
import MetricsDashboard from "./../components/Metrics/MetricsDashboard";
import Unauthorized from "./../components/Unauthorized/Unauthorized";

export default function Home () {
  const { leadId } = useParams();
  const { authState, oktaAuth } = useOktaAuth();
  const [authorized, setAuthorized] = useState(false);

  const setPermissions = (jwt: any) => {
    const role = jwt.appGenesysRole || [];
    const adminRole = role.includes(ADMIN_ROLE);    
    const cloudSupervisor = role.includes(CLOUD_SUPERVISOR);

    if (
      !adminRole &&
      !cloudSupervisor
    ) {
      setAuthorized(false);
    } else {
      setAuthorized(true);
    }
  };

  useEffect(() => {
    async function authenticate () {
      if (!authState) return;

      if (!authState.isAuthenticated) {
        // Signs user in automatically
        const redirectPath = { originalUri: `/${leadId}`};
        await oktaAuth.signInWithRedirect(redirectPath);
      } else {
        // Sets permissions based on user's access token
        oktaAuth.tokenManager.get("accessToken").then((value: any) => {
          const jwt: any = jwt_decode(value.accessToken);
          setPermissions(jwt);
        });
      }
    }

    authenticate();
  }, [authState, oktaAuth]);

  return (
    <div className="Metrics">
      {!authState?.isAuthenticated ? (
        <div>
          <Spinner />
        </div>
      ) : authorized ? (
        <MetricsDashboard/>
      ) : (
        <Unauthorized />
      )}
    </div>
  );
}
