import React from "react";

import { FormField } from "../Input/Input.Styles";
import Select from "../Select/Select";
import { TextStyled } from "../../Text/Text.Styles";
import { SubscriptionStyled } from "./Subscription.Styles";

const options = [
  { value: "Guardrail issue", label: "Guardrail issue" },
  { value: "No price increase", label: "No price increase" },
  {
    value: "Price adjustment: 6 dollars Oct 2020",
    label: "Price adjustment: 6 dollars Oct 2020",
  },
  {
    value: "Price adjustment: 6 dollars Oct 2020 (‘19)",
    label: "Price adjustment: 6 dollars Oct 2020 (‘19)",
  },
  {
    value: "Remote welcome survey completed",
    label: "Remote welcome survey completed",
  },
  { value: "Renewal subscription", label: "Renewal subscription" },
  { value: "Upgrade subscription", label: "Upgrade subscription" },

  {
    value: "Upgrade via customer portal",
    label: "Upgrade via customer portal",
  },
];

interface SubscriptionProps {
  selectValue?: string;
  onSelectChange: (e: string) => void;
  inputValue?: string;
  onInputChange: (e: string) => void;
}

const Subscription = (props: SubscriptionProps): React.JSX.Element => {
  return (
    <SubscriptionStyled data-testid="subscription">
      <FormField>
        <Select
          placeholder="Select a subscription flag"
          options={options}
          onChange={props.onSelectChange}
          value={props.selectValue || ""}
        />
        <TextStyled>Notes</TextStyled>
        <textarea
          value={props.inputValue}
          onChange={(e) => props.onInputChange(e.target.value)}
          rows={1}
        />
      </FormField>
    </SubscriptionStyled>
  );
};

export default Subscription;
