import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { plansAtom } from "./../../../atoms/plans";
import { FormField } from "../Input/Input.Styles";
import Select from "../Select/Select";
import { AddonsStyles } from "./Addons.Styles";
import { TextStyled } from "../../Text/Text.Styles";

export interface OptionType {
  value: any;
  label: string;
  data: {
    initial: string;
    recurring: string;
    selectedInitialAddons: any;
    selectedRecurringAddons: any;
  }  
}

interface AddonsProps {
  planId: number;
  monthly?: boolean;
  isIniAptCmplted?: boolean;
  data?: any;
  setData: any;
}

const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const Addons = (props: AddonsProps): React.JSX.Element => {
  const plans = useRecoilValue(plansAtom);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState<OptionType[]>([]);  
  const [selections, setSelections] = useState<OptionType[]>([]); 
  const [initialTotal, setInitialTotal] = useState<Number>(0);
  const [recurringTotal, setRecurringTotal] = useState<Number>(0);

  useEffect(() => {
    if(props.data.pr_initial_addons) {
      const addons = props.data.pr_initial_addons?.map((item: any)=>item.product_id).join(",") + "," +
                    props.data.pr_recurring_addons?.map((item: any)=>item.product_id).join(",");
      setValue(String(addons));
    }
  }, [props.data, props.data.pr_initial_addons]);

  useEffect(() => {    
    const ids = value.split(',');
    const newSelections = [];
    let initial_addons: any[] = [];
    let recurring_addons: any[] = [];
    for(const thisId of ids) {
      for (const option of options) {        
        if(option.value === thisId) {
          newSelections.push(option);
          option.data.selectedInitialAddons && initial_addons.push(option.data.selectedInitialAddons);
          option.data.selectedRecurringAddons && recurring_addons.push(option.data.selectedRecurringAddons);
          break;
        }  
      }
    }
    setSelections(newSelections);
    props.setData((prevData: any) => ({
      ...prevData,
      selectedInitialAddons: initial_addons,
      selectedRecurringAddons: recurring_addons,
    }));
    
  }, [value]);

  useEffect(() => { 
    setValue("");
    setSelections([]);
  }, [props.planId]);
  
  useEffect(() => {  
    const addonOptions: OptionType[] = []; 
    
    const addons: any = plans[props.planId as keyof typeof plans]['addons'];
    
    for (const key in addons) {    
      
      const thisAddonId = addons[key as keyof typeof addons]['productId'].toString();
      const thisAddonName = toTitleCase(addons[key as keyof typeof addons]['name']);
      const thisAddonInitial = addons[key as keyof typeof addons]['initialMax'];
      const thisAddonRecurring = props.monthly ? addons[key as keyof typeof addons]['monthlyMax'] : addons[key as keyof typeof addons]['recurringMax'];
      const thisIsTaxable = addons[key as keyof typeof addons]['isRechar'];
      const isRecurring = addons[key as keyof typeof addons]['isRecurring'];
      let initialAddons: any;
      let recurringAddons: any;
      isRecurring ? recurringAddons = {
        product_id: addons[key as keyof typeof addons]['productId'],
        amount: thisAddonInitial,
        name: thisAddonName,
        quantity: 1,
        is_taxable: thisIsTaxable
      } : initialAddons = {
        product_id: addons[key as keyof typeof addons]['productId'],
        amount: thisAddonInitial,
        name: thisAddonName,
        quantity: 1,
        is_taxable: thisIsTaxable
      };
      
      addonOptions.push({
        value: thisAddonId,
        label: thisAddonName,
        data: {
          initial: thisAddonInitial,
          recurring: thisAddonRecurring,
          selectedInitialAddons: initialAddons,
          selectedRecurringAddons: recurringAddons,
        }        
      });      
      setOptions(addonOptions);      
    } 
  }, [plans]);

  useEffect(() => {  
    let newInitialTotal = 0;
    let newRecurringTotal = 0;
    selections.map((thisSelection) => {
      if(thisSelection.data) {
        newInitialTotal += parseFloat(thisSelection.data?.initial);
        newRecurringTotal += parseFloat(thisSelection.data?.recurring);
      }
    });

    setInitialTotal(newInitialTotal);
    setRecurringTotal(newRecurringTotal);
  }, [selections]);
  
  return (
    <AddonsStyles data-testid="addons-container">
      <FormField>
        <Select
          placeholder={"Select"}
          options={options}
          onChange={(v) => setValue(v)}          
          value={value}
          isMulti
          withTags
          disabled={props.isIniAptCmplted}
        />
      </FormField>
      {selections.length
        ? selections.map((thisSelection, i) => (
            <div className="addons__item" key={i}>
              <TextStyled color={'#4B5563'} className={'addons__item__label'}>{thisSelection.label}</TextStyled>
              <div className="addons__item__price">
                <TextStyled color={'#4B5563'} className={'addon__item__price__label'}>INITIAL RATE</TextStyled>
                <FormField className="price-input">
                  <input 
                    value={thisSelection.data && parseFloat(thisSelection.data?.initial).toFixed(2)}
                    disabled
                  />
                </FormField>
              </div>
              <div className="addons__item__price">
                <TextStyled color={'#4B5563'}>{props.monthly ? 'MONTHLY' : 'RECURRING'} RATE</TextStyled>
                <FormField className="price-input">
                  <input 
                    value={thisSelection.data && parseFloat(thisSelection.data?.recurring).toFixed(2)}
                    disabled
                  />
                </FormField>
              </div>              
            </div>
          ))
        : null}
        <div className="addons__total">
          <TextStyled 
            color={'#4B5563'} 
            fontWeight="bold"
            className={'price-total'}
          >
            INITIAL TOTAL
          </TextStyled>
          <TextStyled 
            color={'#4B5563'} 
            fontWeight="bold"
            className={'price-total'}
          >
            ${initialTotal.toFixed(2)} USD
          </TextStyled>                
        </div>
        <div className="addons__total">
          <TextStyled 
            color={'#4B5563'} 
            fontWeight="bold"
            className={'price-total'}
          >            
            {props.monthly ? 'MONTHLY' : 'RECURRING'} TOTAL
          </TextStyled>
          <TextStyled 
            color={'#4B5563'} 
            fontWeight="bold"
            className={'price-total'}
          >
            ${recurringTotal.toFixed(2)} USD
          </TextStyled>                
        </div>
      
    </AddonsStyles>
  );
};

export default Addons;
