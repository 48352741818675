import React from "react";
import { Tooltip } from "react-tooltip";

import { IconButtonStyles } from "./Button.Styled";

interface IconButtonProps {
  icon: string;
  type?: "submit" | "reset" | "button" | undefined;
  onClick?: () => void;
  className?: string;
  tooltip?: string;
}

const IconButton = (props: IconButtonProps): React.JSX.Element => {
  const id = Math.random().toString(16).slice(2);
  
  return (
    <div style={{marginLeft: "4px"}}>
      <Tooltip id={id} place="top" content={props.tooltip} />
      <IconButtonStyles
        data-tooltip-id={id}
        type={props.type || "button"}
        className={props.className}
        onClick={props.onClick}
      >
        <img src={`../images/${props.icon}.svg`} />
      </IconButtonStyles>
    </div>
  );
};

export default IconButton;
