import styled from "@emotion/styled";

export const SectionBannerStyled = styled.div`  
  margin-left: 10px;
  border: 2px solid black;
  align-items: center;
  color:  #000;
  font-weight: bold;  
  padding: 8px;
  background-color: #ffd9d9;  
  border-radius: 6px;  
`;
