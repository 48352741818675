import styled from "@emotion/styled";
import { baseGray200, uiGray50, uiTextError } from "styleConstants";

export const PaymentStyled = styled.div`
  & > div {
    border-radius: 8px 8px 0 0;
    border: 1px solid ${baseGray200};
    padding: 8px;
  }

  .info {
    &__panel {
      background: ${uiGray50};
      border-radius: 0px 0px 8px 8px;
    }
  }

  ._p-right {
    float: right;
    margin-top: 10px;
  }
  
  ._large-field {
    width: -webkit-fill-available;    
  }

  .error {
    color: ${uiTextError};
    border: 1px solid ${uiTextError};
  }
`;
