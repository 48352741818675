import * as React from "react";
import { useState, useEffect } from "react";
import { CheckboxStyled } from "./Checkbox.Styles";

interface CheckboxProps {
  checked: boolean;
  onClick?: () => void;
}

const Checkbox = (props: CheckboxProps): React.JSX.Element => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    if (checked !== props.checked) {
      props.onClick && props.onClick();
    }
  }, [checked]);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <CheckboxStyled data-testid="checkbox-input">
      <input
        type="checkbox"
        onChange={() => setChecked((prev) => !prev)}
        checked={checked}
      />
      <span className="checkmark"></span>
    </CheckboxStyled>
  );
};

export default Checkbox;
