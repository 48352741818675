import { API_URL } from "../constants";
import { apiRequest } from "./apiRequest";

export const getUrl = (zip) => `${API_URL}/api/v2/serviceability?zipCode=${zip}`;

const getServicability = async (zip) => {
  return await apiRequest(getUrl(zip), "get");
};

export default getServicability;
