import styled from "@emotion/styled";
import { baseGray200, baseWhite, uiGray50 } from "styleConstants";

export const DispositionsStyles = styled.div`
  display: flex;
  background: ${uiGray50};

  .disposition-children-container {
    display: flex;
    flex-direction: column;
  }

  .disposition-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow: auto;
  }

  .disposition-container {
    background: ${baseWhite};
    left: 0px;
    padding: 16px 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: relative;

    &:not(:last-child) {
      &::before {
        content: ".";
        display: block;
        width: 2px;
        color: transparent;
        height: calc(100% - 38px);
        position: absolute;
        background: ${baseGray200};
        top: 53px;
        left: 27px;
        z-index: 10;
      }
    }

    &:last-child {
      border-bottom: 1px solid #e5e7eb;
    }
  }

  .disposition {
    color: #111827;
    font-size: 12px;
    font-family: Inter;
    font-weight: 600;
    line-height: 16px;
    word-wrap: break-word;
    padding-bottom: 2px;
    text-align: left;
  }

  .disposition-metadata {
    color: #a9a9a9;
    font-size: 10px;
    font-family: Inter;
    font-weight: 400;
    line-height: 12px;
    word-wrap: break-word;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: left;
  }

  .add-disposition-container {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    background: white;
    border-top: 1px ${baseGray200} solid;

    font-family: Inter;

    p {
      padding-bottom: 4px;
    }

    button {
      margin-top: 8px;
    }
  }

  .disposition-item-icon-container {
    padding: 6px;
    background: #009bf0;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .disposition-item-icon {
    content: url(../images/LocationMarkerLight.svg);
    width: 24px;
    height: 24px;
  }
`;
