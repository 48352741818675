import styled from "@emotion/styled";
import { graceGray900 } from "../../styleConstants";

const FONT_SIZES: Record<string, string> = {
  sm: "10px",
  md: "12px",
  lg: "16px",
};

const LINE_HEIGHTS: Record<string, string> = {
  sm: "12px",
  md: "16px",
  lg: "24px",
};

const FONT_WEIGHTS: Record<string, number> = {
  bold: 600,
  medium: 500,
  thin: 400,
};

const TEXT_ALIGN: Record<string, string> = {
  left: "left",
  center: "center",
};

const BORDER_RADIUS: Record<string, string> = {
  sm: "4px",
  md: "4px",
};

interface Props {
  color?: string;
  fontSize?: string;
  textAlign?: string;
  fontWeight?: string;
  border?: string;
  borderRadius?: string;
  padding?: string;
}

export const BorderTextStyled = styled.p<Props>`
  color: ${(props) => props.color || graceGray900};
  font-weight: ${(props) =>
    props.fontWeight ? FONT_WEIGHTS[props.fontWeight] : 500};
  line-height: ${(props) =>
    props.fontSize ? LINE_HEIGHTS[props.fontSize] : "16px"};
  font-size: ${(props) =>
    props.fontSize ? FONT_SIZES[props.fontSize] : "14px"};
  text-align: ${(props) =>
    props.textAlign ? TEXT_ALIGN[props.textAlign] : TEXT_ALIGN.left};
  margin: 0 10px;
  border: ${(props) => props.border};
  border-radius: ${(props) =>
    props.borderRadius ? BORDER_RADIUS[props.borderRadius] : ""};
  padding: ${(props) => props.padding};
    `;

export const InputLabel = styled(BorderTextStyled)<Props>`
  margin-bottom: 4px;
`;

export const BlockLabel = styled(BorderTextStyled)<Props>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
  padding-top: 16px;
`;
