import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import { loadingAtom } from "./../../atoms/loading";
import getRepMetrics from "./../../api/getRepMetrics";
import {
  TableStyled,
} from "./Table.Styles";

import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';

type RepMetric = {
  Rep: string,
  Accounts: number,
  Subscriptions: number,
};

type RepResponse = {
  accounts_count: number,
  username: String,
  subscription_count: number,
};

const columnHelper = createColumnHelper<RepMetric>();

const columns = [
  columnHelper.accessor('Rep', {
    cell: info => info.getValue(),
    footer: info => info.column.id,     
  }),
  columnHelper.accessor('Accounts', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),  
  columnHelper.accessor('Subscriptions', {
    cell: info => info.getValue(),
    footer: info => info.column.id,    
    sortingFn: 'alphanumeric'
  }),
];

const RepMetrics = (): React.JSX.Element => {
  const setLoading = useSetRecoilState(loadingAtom);
  const pageSize = 10;
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });

  async function getReportSalesData () {
    setLoading(true);
    try {
      const reps = (await getRepMetrics()).data.results;           

      const repMetrics: RepMetric[] = [];
      reps.forEach((thisRep: RepResponse) => {             
         const formattedName = thisRep.username.split('.').map((part: String) => { 
            return part[0].toUpperCase() + part.substring(1); 
        }).join(" ");

        repMetrics.push({
          Rep: formattedName,
          Accounts: thisRep.accounts_count,
          Subscriptions: thisRep.subscription_count
        });  
      });

      setData(repMetrics);

    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  useEffect(() => {
    setLoading(false);
    getReportSalesData();
  }, []);

  const [data, setData] = useState<RepMetric[]>([]);

  const table = useReactTable({
    data,
    columns,    
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  return (
    <Stack
      alignItems='center'
      spacing={1}
    >
      <TableStyled id="main-container" data-testid="metrics-dashboard">
        <table className="table rounded-corners">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th className='header-button' key={header.id} colSpan={header.colSpan}>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        <Stack
                          direction='row'
                          justifyContent='center'
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <KeyboardArrowUpIcon/>,
                            desc: <KeyboardArrowDownIcon/>,
                          }[header.column.getIsSorted() as string] ?? null}                      
                        </Stack>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className="row">
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="cell">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>                       
      </TableStyled>
      <Stack
        sx={{
          background:'#fff', 
          borderRadius: 2, 
          p:1
        }}
        direction='row'
        alignItems='center'  
        justifyContent='center'      
        spacing={2}
      >    
        <Stack          
          direction='row'
          alignItems='center'
          spacing={1}
        >  
          <IconButton 
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            aria-label="delete" 
            size="small"
          >
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>     

          <IconButton 
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            aria-label="delete" 
            size="small"
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </Stack>       

        <Divider orientation="vertical" flexItem />
        
        <Stack          
          direction='row'
          alignItems='center'
          spacing={1}
        > 
          <div>Page</div>
          <Typography sx={{fontWeight: 'bold'}}>
            {table.getState().pagination.pageIndex + 1}
          </Typography>
          <Typography sx={{fontWeight: 'bold'}}>
            of
          </Typography>
          <Typography sx={{fontWeight: 'bold'}}>
            {table.getPageCount().toLocaleString()}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={1}
          >
            <Box>
              Go to page:
            </Box>
            <TextField   
              size="small" 
              sx={{width: 80}}                 
              id="outlined-basic" 
              type='number'              
              variant="outlined" 
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
            />            
          </Stack>
        </Stack>

        <Divider orientation="vertical" flexItem />

        <FormControl >          
          <Select                        
            size='small'
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[pageSize, pageSize*2, pageSize*3, pageSize*4, pageSize*5].map(pageSize => (
              
              <MenuItem key={pageSize} value={pageSize}>Show {pageSize}</MenuItem>
            ))}
          </Select>
        </FormControl>
          
      </Stack>
    </Stack>
  );
};

export default RepMetrics;
