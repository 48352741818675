import styled from "@emotion/styled";
import { baseGray200, uiGray50 } from "styleConstants";

export const SubscriptionStyled = styled.div`
  & > div {
    border-radius: 8px;
    border: 1px solid ${baseGray200};
    background: ${uiGray50};
    padding: 8px;

    & > p {
      margin-top: 16px;
      margin-bottom: 4px;
    }
  }
`;
