import styled from "@emotion/styled";

export const SpinnerStyles = styled.div`
  .spinner {
    z-index: 9999;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  .overlay {
    z-index: 9998;
    border-radius: 1px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.05);    
  }

  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
