import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { plansAtom } from "./../../../atoms/plans";
import { FormField } from "../Input/Input.Styles";
import { PlanPricesStyles } from "./PlanPrices.Styles";
import { TextStyled } from "../../Text/Text.Styles";

export interface OptionType {  
  value: any;
  label: string;
  data?: {
    initial: string;
    recurring: string;
  }
}

interface PlanPricesProps {
  monthly?: boolean;
  planId: number;  
}

const PlanPrices = (props: PlanPricesProps): React.JSX.Element => {
  //const [value, setValue] = useState("");
  //const [selections, setSelections] = useState<OptionType[]>([]);
  const plans = useRecoilValue(plansAtom);
  const [selectedPlan, setSelectedPlan] = useState<any>();

  useEffect(() => {    
    for (const key in plans) {
      if(key === props.planId.toString()) {
        setSelectedPlan(plans[key as keyof typeof plans]);
      }
    }
  }, [props.planId]);

  return (
    <PlanPricesStyles data-testid="plan-prices-container">
      {selectedPlan &&
      <div className="plan-prices__table">
        <div className="plan-prices__table__row">
          <TextStyled
            color={'#4B5563'}
            className={'price-header'}
          >
            INITIAL FLOOR
          </TextStyled>
          <TextStyled
            color={'#4B5563'}
            className={'price-header'}
          >
            INITIAL CEILING
          </TextStyled>
        </div>
        <div className="plan-prices__table__price-row">
          <FormField className="price-input price">
            <input
              value={selectedPlan.prices[0].initialMin}
              disabled
            />
          </FormField>
          <FormField className="price-input price">
            <input
              value={selectedPlan.prices[0].initialMax}
              disabled
            />
          </FormField>
        </div>
        <div className="plan-prices__table__row">
          <TextStyled
            color={'#4B5563'}
            className={'price-header'}
          >
            {props.monthly ? 'MONTHLY' : 'RECURRING'} FLOOR
          </TextStyled>
          <TextStyled
            color={'#4B5563'}
            className={'price-header'}
          >
            {props.monthly ? 'MONTHLY' : 'RECURRING'} CEILING
          </TextStyled>
        </div>
        <div className="plan-prices__table__price-bottom-row">
          <FormField className="price-input">
            <input
              value={props.monthly ? selectedPlan.prices[0].monthlyMin : selectedPlan.prices[0].recurringMin}
              disabled
            />
          </FormField>
          <FormField className="price-input">
            <input
              value={props.monthly ? selectedPlan.prices[0].monthlyMax : selectedPlan.prices[0].recurringMax}
              disabled
            />
          </FormField>
        </div>
      </div>}

    </PlanPricesStyles>
  );
};

export default PlanPrices;
