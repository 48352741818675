import { API_URL } from "../constants";
import { apiRequest } from "./apiRequest";

export const getUrl = (leadId) => `${API_URL}/api/v1/event/${leadId}/disposition`;

const createDisposition = async (leadId, dispositionId) => {
  const url = getUrl(leadId);
  const body = {
    disposition: dispositionId,
    includeData: 1,
  };

  return await apiRequest(url, "post", JSON.stringify(body));
};

export default createDisposition;
