import styled from "@emotion/styled";
import { baseGray200, baseWhite } from "styleConstants";

export const SectionHeaderStyles = styled.div`
  box-sizing: border-box;
  height: 65px;
  padding-top: 21px;
  padding-bottom: 20px;
  background: ${baseWhite};
  border-bottom: 1px ${baseGray200} solid;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;

  .container {
    margin-left: 10px;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
    display: inline-flex;
  }

  .header-label {
    color: #111827;
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    word-wrap: break-word;
  }

  .header-icon-container {
    width: 24px;
    height: 24px;
    margin-top: 3px;
    margin-bottom: 4px;
    margin-left: 4px;

    justify-content: center;
    align-items: center;
    display: flex;
  }

  .header-icon {
    width: 24px;
    height: 24px;
  }
`;
