import React, { useEffect, useState } from "react";

import { SectionHeaderStyles } from "./SectionHeader.Styles";
import { SectionBannerStyled } from "./SectionBanner.Styles";
import { FlexContainer } from "components/Flex/Flex.Styles";
import { TextStyled } from "components/Text/Text.Styles";
import { CallBadgeDividerStyle } from "components/LeadManagement/IncomingCallInfo/IncomingCallInfoComponent.Styles";
import { graceGray600 } from "styleConstants";
import { useRecoilValue } from "recoil";
import { leadAtom } from "atoms/lead";

interface SectionHeaderProps {
  label: string;
  icon: string;
  monthlyBanner?: boolean;  
}

export const getContentTemplate = (icon: string) =>
  `url("../images/${icon}.svg")`;

const SectionHeader = (props: SectionHeaderProps): React.JSX.Element => {
  const leadData: any = useRecoilValue(leadAtom);
  const [salesRep, setSalesRep] = useState<any>({});
  const [salesOffice, setSalesOffice] = useState<any>({});

  useEffect(() => {
    if(leadData) {
      setSalesRep(leadData.pr_sold_by);
      setSalesOffice(leadData.pr_source);
    }
  }, [leadData]);


  return (
    <SectionHeaderStyles style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <SectionHeaderStyles>
        <div className="container" data-testid="section-header-container">
          <div className="header-icon-container">
            <div
              className="header-icon"
              style={{ content: getContentTemplate(props.icon) }}
            />
          </div>
          <div className="header-label">{props.label}</div>
        </div>
        
        {props.monthlyBanner && 
          <SectionBannerStyled>                  
              Monthly Billing is the only supported payment plan in this area               
          </SectionBannerStyled>
        }
      </SectionHeaderStyles>
      {props.label !== "Notes" &&
        <FlexContainer data-testid="call-badge" style={{padding: "20px"}}>
          <TextStyled color={graceGray600} fontWeight="thin" fontSize="lg" border="1px solid gray" padding="8px" style={{borderRadius: "8px"}}>
            {"Sales Rep: "} {salesRep?.first_name ? salesRep?.first_name : "N/A"} {salesRep?.last_name}
          </TextStyled>
          <CallBadgeDividerStyle>.</CallBadgeDividerStyle> 
          
          <TextStyled color={graceGray600} fontWeight="thin" fontSize="lg" border="1px solid gray" padding="8px" style={{borderRadius: "8px"}}>
            {"Sales Source: "}{salesOffice?.name ? salesOffice?.name : "N/A"}
          </TextStyled>
        </FlexContainer>
      }
    </SectionHeaderStyles>
  );
};

export default SectionHeader;
