import { atom } from "recoil";

export interface Employee {
  masterEmployeeId?: string;
}

const employeeAtom = atom({
  key: "employee",
  default: {} as Employee,
});

export { employeeAtom };
