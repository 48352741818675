import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { datadogRum } from '@datadog/browser-rum';

import "./styles.css";
import Home from "./pages/Home";
import Metrics from "./pages/Metrics";


import { DATA_DOG_ENV } from "./constants";
import { ISSUER, CLIENT_ID, SCOPES, STATE } from "./config/okta";

export default function App () {

  // Okta authentication
  const oktaAuth = new OktaAuth({
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: SCOPES,
    state: STATE,
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri, window.location.origin),
    );
  };

  datadogRum.init({
    applicationId: '9a39bc3c-b667-44da-a092-24340dfd0543',
    clientToken: 'pub4119a1fdc2b788193a334f4f40514f60',
    site: 'us5.datadoghq.com',
    service: 'sales-hub-react',
    env: DATA_DOG_ENV,    
    version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route path="/:leadId" element={<Home />}>
            <Route path=":leadId" element={<Home />} />
          </Route>
          <Route path="/metrics" element={<Metrics />} />
          <Route path="/login/callback" element={<LoginCallback />} />
        </Routes>
      </Security>
    </BrowserRouter>
  );
}
