import styled from "@emotion/styled";
import { baseGray200, baseWhite } from "styleConstants";

export const LeadActionButtonsStyles = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 10px 16px;
  border-top: 1px solid ${baseGray200};
  background: ${baseWhite};

  & > div {
    flex-grow: 1;
  }

  button:last-child {
    margin-left: 16px;
  }
`;

export const LeadManagementStyled = styled.div`
  border-radius: 8px;
  border: 1px solid ${baseGray200};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  display: flex;
  flex-direction: column;

  .lead-page {
    flex-grow: 1;
    max-height: 547px;
    height: 547px;

    & > div:first-of-type {
      border-right: 1px solid ${baseGray200};
    }

    &__step {
      width: 100%;
    }

    &__section__wrapper {
      max-height: calc(100% - 65px - 61px);
      height: 100%;
    }

    & > div:first-of-type {
      max-width: calc(100% - 403px);
    }

    .notes-parent-container,
    .disposition-parent-container,
    &__menu {      
      width: 290px;
      min-width: 220px;
    }

    .notes-parent-container,
    .disposition-parent-container {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }

    &._setter {
      & > div:first-of-type {
        max-width: 50%;
      }
      .notes-parent-container {
        max-width: unset;
        width: unset;
      }
    }

    & > div {
      flex-grow: 1;
    }

    &__main {
      height: 100%;
    }
  }
`;

export const FormStyled = styled.div`
  padding: 10px;
  background: ${baseWhite};

  .error-tooltip {
    background-color: #F05252;
  }

  & > div {
    flex-wrap: wrap;
  }

  &.info-form {
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
  }

  .info-form {
    &__form-group {
      margin-bottom: 16px;
      flex-basis: 50%;
      max-width: 50%;
      box-sizing: border-box;

      &__block-label {
        border-top: 1px solid ${baseGray200};
      }

      &._no-border {
        .info-form__form-group__block-label {
          border: none;
          padding-top: 0;
        }
      }

      &._m-top {
        margin-top: 16px;
      }

      &._small-field {
        flex-basis: 25%;
        max-width: 25%;
      }

      &._large-field {
        flex-basis: 100%;
        max-width: 100%;
      }

      &._p-left {
        padding-left: 8px;
      }

      &._p-right {
        padding-right: 8px;
      }

      &._medium-field {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3);
      }

      &._double-padding {
        padding-right: 8px !important;
        padding-left: 8px !important;
      }

      &._no-padding {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }

      &:nth-of-type(2n + 1) {
        &.price-input {
          &::after {
            right: 21px;
          }
        }
      }

      &:nth-of-type(2n) {
        &.price-input {
          &::before {
            left: 21px;
          }
        }
      }      
    }
    
    &.hidden {
      display: none;
    }
  }

  .dateInput {
      margin-bottom: 16px;
      flex-basis: 100%;
      max-width: 100%;
      box-sizing: border-box;
  }
`;
