import styled from "@emotion/styled";
import { baseGray200, uiGray50 } from "styleConstants";

export const AddonsStyles = styled.div`
  border-radius: 8px;
  border: 1px solid ${baseGray200};
  background: ${uiGray50};
  padding: 8px;

  .addons {
    &__item {
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: space-between;      

      &__label {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;        
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${baseGray200};
      }

      &__price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &__label {
          font-size: 40px;
          color: red;          
        }
      }

      &__actions {
        display: flex;
        align-items: center;
      }

      input {
        background-color: #FFF;
      }

      .price-input {
        max-width: 136px;        
      }

      .price-total {        
        font-size: 18px;
      }

      &__delete {
        background: url("../images/Trash.svg");
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    &__total {
      margin-top: 20px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .price-total {        
        font-size: 18px;
      }

      &__label {
        font-size: 40px;
        color: red;          
      }
    }
  }
`;
