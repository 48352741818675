import { atom } from "recoil";

export interface ErrorBanner {
  open: boolean;
  message: string;
}

const errorBannerAtom = atom({
  key: "errorBanner",
  default: {}
});

export { errorBannerAtom };
