import styled from "@emotion/styled";
import { baseGray200, uiGray50 } from "styleConstants";

export const PlanPricesStyles = styled.div`
  border-radius: 8px;
  border: 1px solid ${baseGray200};
  background: ${uiGray50};  

  .price-input {
    max-width: 48%;

    input {
      text-align: right;
      background: #FFF;
    }
  }

  .plan-prices {
    &__table {
      &__row, &__price-row, &__price-bottom-row {
        display: flex;
        padding: 15px 15px;
        justify-content: space-between;

        p {
          flex-grow: 1;
          width: 25%;
        }

        .price-header {
          justify-content: center;
        }
      }

      &__price-row {
        background-color: #FFF;
      }

      &__price-bottom-row {
        background-color: #FFF;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    } 
  }
`;
