import React from "react";

import { UnauthorizedStyles, UnauthorizedMessage } from "./Unauthorized.Styles";

export default function Unauthorized () {
  const message = "WE'RE SORRY, YOU ARE NOT AUTHORIZED";
  return (
    <UnauthorizedStyles data-testid="unauthorized">
      <UnauthorizedMessage>{message}</UnauthorizedMessage>
    </UnauthorizedStyles>
  );
}
