import { API_URL } from "../constants";
import { apiRequest } from "./apiRequest";

export const getUrl = (leadId) => `${API_URL}/api/v1/event/update/${leadId}`;

export const updateLead = async (leadId, lead) => {
  const url = getUrl(leadId);
  const body = { ...lead };

  return await apiRequest(url, "post", JSON.stringify(body));
};

