import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { Tooltip } from "react-tooltip";
import InputMask from "react-input-mask";
import { FormField, ValidityIcon, ValidityIconWrapper } from "./Input.Styles";
import { leadAtom, Lead } from "./../../../atoms/lead";

import getServicable from "./../../../api/getServicable";
import { InputLabel } from "./../../../components/Text/Text.Styles";

interface ZipInputProps {
  value?: string;
  onChange: (e: string) => void;
  placeholder: string | undefined;
  mask?: string;
  isServiceability?: boolean;
}

interface status {
  icon: string;
  text: string;
}

export const info = {
  invalid: { icon: "ExclamationCircle", text: "Invalid Zip Code" },
  success: { icon: "CheckCircle", text: "Serviceable" },
  failure: { icon: "XCircle", text: "Not Serviceable" },
  loading: { icon: "ThreeDots", text: "Loading..." },
};

const mask = "99999";

const ZipInput = (props: ZipInputProps): React.JSX.Element => {
  const leadData = useRecoilValue(leadAtom);
  const [contactId, setContactId] = useState<number | undefined>(0);
  const [systemId, setSystemId] = useState<number | undefined>(0);
  const [status, setStatus] = useState<status>(info.invalid);

  const isServicable = async (zip: string) => {
    try {
      setStatus(info.loading);
      const response = await getServicable(zip, systemId, contactId);
      
      if (response.data) {
        setStatus(info.success);
      } else {
        setStatus(info.failure);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (props.value?.match(/[0-9]{5}/)) {
      isServicable(props.value);
    } else {
      setStatus(info.invalid);
    }
  }, [props.value]);

  useEffect(() => {
    let leadContactId = leadData ? (leadData as Lead).contact_id : 0;
    let leadSystemId = leadData ? (leadData as Lead).system_id : 0;
    setContactId(leadContactId);
    setSystemId(leadSystemId);
  }, [leadData]);

  return (
    <>
      <FormField className="zip-input" data-testid="zip-input">
        <div>
          <InputLabel>Zip Code</InputLabel>
          <ValidityIconWrapper>
            <ValidityIcon
              className="zip-input__icon"
              data-tooltip-id="validity-tooltip"
              style={{
                content: `url("../images/${status.icon}.svg")`,
              }}
            ></ValidityIcon>
          </ValidityIconWrapper>
        </div>

        <Tooltip id="validity-tooltip" place="top" content={status.text} />
        <InputMask
          value={props.value}
          onChange={(e) => {
            props.onChange(e?.target?.value);
          }}
          placeholder={props.placeholder}
          mask={mask}
        />
      </FormField>
    </>
  );
};

export default ZipInput;