import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { FormField } from "../Input/Input.Styles";
import { TextStyled } from "../../Text/Text.Styles";
import { PaymentStyled } from "./Payment.Styles";
import RadioInput from "../RadioInput/RadioInput";
import { OptionType } from "../RadioInput/RadioInput";
import { ButtonPrimary } from "./../../Buttons/Button.Styled";
import { leadAtom, Lead } from "../../../atoms/lead";

interface PaymentProps {
  options: (OptionType & { options?: OptionType[] })[];
  defaultValue?: any
  data: Lead;
  //onChange: (e: any) => void;
  onSend: (option: String) => void;
}

const Payment = (props: PaymentProps): React.JSX.Element => {
  const [option, setOption] = useState("");
  const prLeadData: any = useRecoilValue(leadAtom);

  //first take reponse lead data after saving to PR
  //if it's empty - get it from personal info
  const phone = (prLeadData as Lead)?.phone_number
    ? (prLeadData as Lead).phone_number
    : props.data.phone_number;
  const email = (prLeadData as Lead)?.email_address
    ? (prLeadData as Lead).email_address
    : props.data.email_address;


  const changeOption = (e: any) => {
    setOption(e);
    //props.onChange(e);
  };

  useEffect(() => {
    if(props.defaultValue) {
      changeOption(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
  <PaymentStyled data-testid="payment">
    <RadioInput name="payment_option" options={props.options} onChange={changeOption} defaultValue={props.defaultValue} />
    {option && (
      <FormField className="info__panel">
        {option === "email" && (
          <div className={`payment__additional-info__${option}`}>
            <div>
              <TextStyled>Email address</TextStyled>
              <input
                placeholder="e.g., john.doe@example.com"
                name="email_address"
                className="_large-field"
                value={email}
                readOnly
              />
            </div>
            <ButtonPrimary className='_p-right' onClick={() => props.onSend('email')}>Send</ButtonPrimary>
          </div>
        )}
        {option === "sms" && (
          <div className={`payment__additional-info__${option}`}>
            <div>
              <TextStyled>Phone number</TextStyled>
              <input
                placeholder="e.g., 555-867-5309"
                name="phone_number"
                className="_large-field"
                value={phone}
                readOnly
              />              
            </div>
            <ButtonPrimary className='_p-right' onClick={() => props.onSend('sms')}>Send</ButtonPrimary>
          </div>
        )}
      </FormField>
    )}
    </PaymentStyled>
  );
};

export default Payment;
