import styled from "@emotion/styled";
import { baseWhite, pine } from "styleConstants";


export const InteractionsToSellStyled = styled.div`     
    background: ${baseWhite};
    padding: 20px;
    border-radius: 10px;  

    .average {
      color: ${pine};
      font-size: 20px;
    }
`;
