import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { loadingAtom } from "./../../atoms/loading";
import getReportSales from "./../../api/getReportSales";
import {
  TableStyled,
} from "./Table.Styles";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

type ReportSaleWeek = {
  Week: string,
  Sunday: number,
  Monday: number,
  Tuesday: number,
  Wednesday: number,
  Thursday: number,
  Friday: number,
  Saturday: number,
  Total: number
};

const columnHelper = createColumnHelper<ReportSaleWeek>();

const columns = [
  columnHelper.accessor('Week', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('Sunday', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('Monday', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('Tuesday', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('Wednesday', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('Thursday', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('Friday', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('Saturday', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('Total', {
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
];

const WeeklySalesReport = (): React.JSX.Element => {
  const setLoading = useSetRecoilState(loadingAtom);

  async function getReportSalesData () {
    setLoading(true);
    try {
      const response = (await getReportSales()).data.results;
      const weeks: ReportSaleWeek[] = [];
      Object.keys(response).forEach(thisKey => {
        if(thisKey !== 'sql') {
          weeks.push({
            Week: thisKey,
            Sunday: response[thisKey][0],
            Monday: response[thisKey][1],
            Tuesday: response[thisKey][2],
            Wednesday: response[thisKey][3],
            Thursday: response[thisKey][4],
            Friday: response[thisKey][5],
            Saturday: response[thisKey][6],
            Total: response[thisKey][7],
          });
        }
      });

      setData(weeks);

    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  useEffect(() => {
    setLoading(false);    
    getReportSalesData();
  }, []);

  const [data, setData] = React.useState<ReportSaleWeek[]>([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableStyled id="main-container" data-testid="metrics-dashboard">
      <table className="rounded-corners">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className="header">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className="row">
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className="cell">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </TableStyled>
  );
};

export default WeeklySalesReport;
