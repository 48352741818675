import styled from "@emotion/styled";
import { baseGray200 } from "styleConstants";

export const LeadBarStyled = styled.div`
  padding: 14px 16px;
  background: url("../images/FooterImage.png");
  background-repeat: no-repeat;
  background-size: 108%;
  background-position: 100%;
  border-bottom: 1px solid ${baseGray200};

  .lead-modal {
    &__call-icon {
      background: url("../images/AptiveCallIcon.svg");
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      opacity: 0.7;
      background-position: 50%;
      border: 1px solid ${baseGray200};
      border-radius: 6px;
      margin-right: 16px;
    }
  }
`;
