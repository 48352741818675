import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import {
  InteractionsToSellStyled,
} from "./InteractionsToSell.Styles";

import AptiveDatePicker from './common/AptiveDatePicker';

import {
  Stack,
  Typography,
} from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

import { loadingAtom } from "./../../atoms/loading";
import getInteractionsToSell from "./../../api/getInteractionsToSell";

type InteractionsItem = {
  contact_id: string,
  event_count: string,
  phone: string,
};

const InteractionsToSell = (): React.JSX.Element => {  
  const setLoading = useSetRecoilState(loadingAtom);
  const [interactionsData, setInteractionsData] = useState<InteractionsItem[]>([]);
  const [average, setAverage] = useState<Number>(0);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(1, 'week'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [validRange, setValidRange] = useState(true);

  async function getReportSalesData (start: Dayjs | null, end: Dayjs | null) {
    setLoading(true);
    try {
      const startParam = start?.format('YYYY-MM-DD');
      const endParam = end?.format('YYYY-MM-DD');
      const data: InteractionsItem[] = (await getInteractionsToSell(startParam, endParam)).data.results;
      
      setInteractionsData(data);

      let total = 0;
      data.forEach((thisData: InteractionsItem) => {
        total += parseInt(thisData.event_count);
      });
      
      setAverage(Math.round(total / data.length));
      
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  useEffect(() => {
    setLoading(false);    
    getReportSalesData(startDate, endDate);
  }, []);  

  useEffect(() => {
    if(!startDate?.isBefore(endDate)) {
      setValidRange(false);
    } else {
      setValidRange(true);
      getReportSalesData(startDate, endDate);
    }        
  }, [startDate, endDate]);  

  return (
    <InteractionsToSellStyled id="main-container" data-testid="metrics-dashboard">
      <Stack
        justifyContent='center'
        alignItems='center'
        spacing={2}
      >        
        <Stack direction='row' spacing={1}>
          <AptiveDatePicker              
            label="Start Date"
            value={startDate}
            onChange={(newValue: any) => setStartDate(newValue)}
          />
          <AptiveDatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue: any) => setEndDate(newValue)}
          />
        </Stack>
        

        {!validRange ?
          <Typography variant={'h6'} className={'average'}>
            Please select a valid range.
          </Typography>
        :
          <Stack>
            <Stack
              spacing={4}
              justifyContent={'space-between'}
              direction='row'
            >
              <Typography variant={'h6'} className={'average'}>
                  Average Interactions: 
              </Typography>
              <Typography variant={'h6'} className={'average'}>
                {average.toString()}
              </Typography>
            </Stack>
            
            <Stack
              spacing={1}
              justifyContent={'space-between'}
              direction='row'
            >
              <Typography variant={'h6'} className={'average'}>
                  Contacts Made: 
              </Typography>
              <Typography variant={'h6'} className={'average'}>
                {interactionsData.length}
              </Typography>
            </Stack>
          </Stack>
        }
        
      </Stack>
    </InteractionsToSellStyled>
  );
};

export default InteractionsToSell;

