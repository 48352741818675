import styled from "@emotion/styled";
import { baseGray200, baseWhite, uiTextPlaceholder } from "styleConstants";

export const CallBadgeStyle = styled.div`
  display: flex;
  border: 1px solid ${baseGray200};
  width: fit-content;
  padding: 6px 16px;
  border-radius: 4px;
  background: ${baseWhite};
  align-items: center;
  position: relative;

  
`;

export const CallBadgeDividerStyle = styled.div`
    
    font-size: 30px;
    width: 22px;
    height: 22px;
    line-height: 5px;
    color: ${uiTextPlaceholder};
  
`;

