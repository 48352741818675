import styled from "@emotion/styled";
import { baseWhite, baseGray200 } from "styleConstants";

export const ErrorBannerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:  ${baseWhite};
  font-weight: bold;
  padding: 6px 16px;
  background-color: #c9564a;
  background-repeat: no-repeat;
  background-size: 108%;
  background-position: 100%;
  border-bottom: 1px solid ${baseGray200};

  .error-banner-icon {
    width: 40px;    
  }

`;
