import { API_URL } from "../constants";
import { apiRequest } from "./apiRequest";

export const getUrl = (id) => `${API_URL}/api/v1/crm/billinginfo/process?id=${id}`;

export const billing = async (id, billingInfo) => {
  const url = getUrl(id);
  const body = { ...billingInfo };

  return await apiRequest(url, "post", JSON.stringify(body));
};

