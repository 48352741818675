export const baseBorder = "#E5E7EB";
export const baseWhite = "#FFFFFF";
export const graceGray600 = "#4B5563";
export const graceGray50 = "#F9FAFB";
export const graceGray900 = "#111827";
export const baseGray200 = "#E5E7EB";
export const pine = '#344C38';
export const bluePrimary = "#007AFF";
export const bluePrimaryHover = "#0066D5";
export const uiGray50 = "#F9FAFB";
export const green100 = "#DEF7EC";
export const green400 = "#31C48D";
export const green900 = "#014737";
export const placeholderColor = "#A9A9A9";
export const basePageHoverBg = "#F3F4F6";
export const gray200 = "#E5E7EB";
export const gray400 = "#9CA3AF";
export const uiTextError = "#F05252";
export const uiTextPlaceholder = "#8F8F8F";
export const amSpotColor = "#76A9FA";
export const pmSpotColor = "#FACA15";
