import { API_URL } from "../constants";
import { apiRequest } from "./apiRequest";

export const getUrl = (eventId) => `${API_URL}/api/v1/crm/customer/create/${eventId}`;

export const createCustomer = async (eventId, planInfo) => {
  const url = getUrl(eventId);
  const body = { ...planInfo };

  return await apiRequest(url, "post", JSON.stringify(body));
};

