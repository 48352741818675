import React, { useCallback} from "react";
import { Lead } from "atoms/lead";
import { ButtonAmSpot, ButtonNaSpot } from "components/Buttons/Button.Styled";
import { FlexContainer } from "components/Flex/Flex.Styles";
import { TextStyled } from "components/Text/Text.Styles";
import { getWeekdayName } from "utils/getWeekday";
import { LeadBillingInfo, LeadPlanInfo, LeadSchedulingInfo } from "../LeadManagement";
import { slots } from "../../../constants";

interface ScheduleAppointmentProps {
    date: {
        additions?: any[];
        default?: boolean; 
        value: any; 
        label: string,
    }
    eventId?: string;
    setData: React.Dispatch<React.SetStateAction<Lead & LeadPlanInfo & LeadBillingInfo & LeadSchedulingInfo>>;
    data: any;
    scheduledDate?: any;
    scheduledSlot?: any;
    setScheduledDate?: React.Dispatch<React.SetStateAction<any>>;
    setScheduledSlot?: React.Dispatch<React.SetStateAction<any>>;
    onSlotSelect?: (isSelected: boolean, date: any, slot: string, addition: any) => void;
    selectedDate?: any;
    selectedSlot?: string;
}

export const ScheduleAppointment = (props: ScheduleAppointmentProps) => {
    const isSlotAvailable = useCallback((slot: string) => {
        const [start, end] = slot.split('-');
        const startHour = parseInt(start);
        const endHour = parseInt(end);
        if(props.date?.additions) {
            return props.date.additions.some(addition => {
                const additionHour = parseInt(addition.slot.split('-')[0].split(':')[0]);
                return additionHour >= startHour && additionHour < endHour;
            });
        }
        return false;
    }, [props.date]);

    const getAvailableAddition = useCallback((slot: string) => {
        const [start, end] = slot.split('-');
        const startHour = parseInt(start);
        const endHour = parseInt(end);
        if(props.date?.additions) {
            return props.date.additions.find(addition => {
                const additionHour = parseInt(addition.slot.split('-')[0].split(':')[0]);
                return additionHour >= startHour && additionHour < endHour;
            });
        }
    }, [props.date]);

    const handleSlotClick = useCallback((date: any, slot: string) => {
        const addition = getAvailableAddition(slot);
        props.onSlotSelect && props.onSlotSelect(true, date, slot, addition);
    }, [getAvailableAddition, props.onSlotSelect]);

    return (
        <div key={props.date?.label} style={{marginRight: "10px"}} >
            <div className="spot-card">
            {props.date?.additions && (
                <>  
                    <FlexContainer column={true} gap={1}>
                        <TextStyled fontSize="14">{getWeekdayName(props.date.value)}</TextStyled>
                        <TextStyled fontSize="14" style={{marginBottom: '10px'}}>{props.date.value.substr(-2)}</TextStyled>
                    </FlexContainer>
                    
                    <FlexContainer column={true} gap={5}>
                        {slots.map((item: any, index: number) => (
                            isSlotAvailable(item.slot) ? (
                                <ButtonAmSpot 
                                    key={index}
                                    onClick={() => handleSlotClick(props.date, item.slot)} 
                                    isSelected={props.selectedDate?.value === props.date.value && props.selectedSlot === item.slot}
                                >
                                    {item.label}
                                </ButtonAmSpot>
                            ) : (
                                <ButtonNaSpot key={index} disabled>{item.label}</ButtonNaSpot>
                            )
                        ))}
                    </FlexContainer>
                </>
            )}
            </div>
        </div>
    );
};