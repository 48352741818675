import styled from "@emotion/styled";
import { baseGray200, uiGray50 } from "styleConstants";

export const SearchStyles = styled.div`
  .search-modal {
    & > div {
      width: 80%;
    }

    &__fields {
      display: flex;
      align-items: center;

      button {
        flex-grow: 1;
        margin-left: 8px;
      }
    }

    &__body {
      flex-direction: column;
    }

    &__close {
      cursor: pointer;
      content: url(../images/X.svg);
      width: 24px;
      height: 24px;
    }

    &__table {
      &__row {
        display: flex;
        padding: 15px 0;
        border-bottom: 1px solid ${baseGray200};
        cursor: pointer;

        &:first-of-type {
          border-top: 1px solid ${baseGray200};
        }

        p {
          flex-grow: 1;
          width: 25%;
        }
      }

      &__row:hover {
        background-color: ${uiGray50};
      }

      &__no-leads {
        text-align: center;
      }
    }

    .info-form {
      flex-basis: calc(100% / 5 * 4);
      max-width: calc(100% / 5 * 4);

      &__form-group {
        flex-basis: 25%;
        max-width: 25%;
      }
    }
  }
`;
