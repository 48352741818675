import styled from "@emotion/styled";
import { baseWhite, pine } from "styleConstants";


export const MetricsDashboardStyled = styled.div`   

  .app-bar {
    background-color: ${baseWhite};
  }

  .app-bar-toolbar {
    justify-content: space-between;
  }

  .app-bar-logo {
    flex: 1;
    max-width: 80px;
  }

  .main-container {
    margin: 50px 0px 0px 0px;
  }

  .card {
    background-color: ${pine};    
  }

  .card-header {
    color: ${baseWhite};
    text-align: center;
    padding-bottom: 0;
  }

  .table-wrapper {
    overflow-x: auto;
  }
`;
