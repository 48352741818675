import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

import {
  ENV,
  ADMIN_ROLE,
  CLOSER_ROLE,
  INBOUND_ROLE,
  OUTBOUND_ROLE,
  TRAINING_ROLE,
  ACCOUNT_SPECIALIST,
  CLOUD_SUPERVISOR
} from "./../constants";

import { Employee, employeeAtom } from "./../atoms/employee";

import Spinner from "./../components/Spinner/Spinner";
import LeadManagement from "./../components/LeadManagement/LeadManagement";
import Unauthorized from "./../components/Unauthorized/Unauthorized";

export default function Home () {
  const { leadId } = useParams();
  const { authState, oktaAuth } = useOktaAuth();
  const setEmployee = useSetRecoilState<Employee>(employeeAtom);
  const [authorized, setAuthorized] = useState(false);
  const [isCloser, setIsCloser] = useState(false);
  const [isOutbound, setIsOutbound] = useState(false);

  const setPermissions = (jwt: any) => {
    const role = jwt.appGenesysRole || [];
    const adminRole = role.includes(ADMIN_ROLE);
    const inboundRole = role.includes(INBOUND_ROLE);
    const outboundRole = role.includes(OUTBOUND_ROLE);
    const closerRole = role.includes(CLOSER_ROLE);
    const trainingRole = role.includes(TRAINING_ROLE);
    const accountSpecialistRole = role.includes(ACCOUNT_SPECIALIST);
    const cloudSupervisor = role.includes(CLOUD_SUPERVISOR);

    if (
      !adminRole &&
      !inboundRole &&
      !outboundRole &&
      !closerRole &&
      !trainingRole &&
      !accountSpecialistRole &&
      !cloudSupervisor
    ) {
      setAuthorized(false);
    } else {
      setAuthorized(true);
    }
    
    if (outboundRole) {
      setIsOutbound(true);
    } else if (adminRole || cloudSupervisor || closerRole || trainingRole) {
      setIsCloser(true);      
      const employeeId = (ENV === "testing" && jwt.appGenesysPestRoutesTESTMasterEmployeeID) ? jwt.appGenesysPestRoutesTESTMasterEmployeeID : jwt.appGenesysPestRoutesMasterEmployeeID;
      setEmployee({
        masterEmployeeId: employeeId,
      });
    }
  };

  useEffect(() => {
    async function authenticate () {
      if (!authState) return;

      if (!authState.isAuthenticated) {
        // Signs user in automatically
        const redirectPath = { originalUri: `/${leadId}`};
        await oktaAuth.signInWithRedirect(redirectPath);
      } else {
        // Sets permissions based on user's access token
        oktaAuth.tokenManager.get("accessToken").then((value: any) => {
          const jwt: any = jwt_decode(value.accessToken);
          setPermissions(jwt);
        });
      }
    }

    authenticate();
  }, [authState, oktaAuth]);

  return (
    <div className="Home">
      {!authState?.isAuthenticated ? (
        <div>
          <Spinner />
        </div>
      ) : authorized ? (
        <LeadManagement
          leadId={leadId || "1"}
          closer={isCloser}
          outbound={isOutbound}
        />
      ) : (
        <Unauthorized />
      )}
    </div>
  );
}
