import { Loader } from "@googlemaps/js-api-loader";

import { GOOGLE_API_KEY } from "../../../constants";
import { updatePlaceData } from "./autoComplete";

let autoCompleteBilling: any = null;
let props: any = null;

const handlePlaceSelect = () => {
  // Get the place details from the autocomplete object.
  const place = autoCompleteBilling.getPlace();

  const placeData = updatePlaceData(place);

  props.setData((prev: any) => ({
    ...prev,
    ["ach_billing_state"]: placeData.province,
    ["ach_billing_city"]: placeData.city,
    ["ach_billing_zip"]: placeData.postcode,
    ["ach_billing_address"]: placeData.address,
    ["billing_county"]: placeData.county,
    ["billing_latitude"]: place.geometry.location.lat(),
    ["billing_longitude"]: place.geometry.location.lng(),    
  }));
};

const addAutocomplete = (google: any) => {
  const addressInput = document.getElementById(
    "ach_billing_address",
  ) as HTMLInputElement;

  autoCompleteBilling = new google.maps.places.Autocomplete(addressInput, {
    componentRestrictions: { country: ["us"] },
  });
  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autoCompleteBilling.addListener("place_changed", handlePlaceSelect);
};

export const billingLoadAutoComplete = (data: any) => {
  props = data;
  // Google maps loader for address
  const loader = new Loader({
    apiKey: GOOGLE_API_KEY,
    version: "weekly",
    libraries: ["places"],
  });

  loader
    .load()
    .then((google) => {
      addAutocomplete(google);
    })
    .catch((e) => {
      console.log(e);
    });
};
