export const modifySpots = (dates, spots) => {
  const modifiedSpots = {};

  dates.forEach(date => {
    modifiedSpots[date] = [];
  });

  spots.forEach(spot => {
    if (modifiedSpots[spot.date]) {
      modifiedSpots[spot.date].push({
        spotID: spot.spotID,
        slot: `${spot.start.slice(0, 5)}-${spot.end.slice(0, 5)}`,
        window: spot.window,
        isAroSpot: spot.isAroSpot,
        isAnytime: spot.isAnytime
      });
    }
  });

  return modifiedSpots;
};