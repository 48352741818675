import React from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type DatePickerProps = {
  label: string,
  value: any,
  onChange: any,
};

const AptiveDatePicker = (props: DatePickerProps): React.JSX.Element => {  
  return (    
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={props.label}
        value={props.value}
        onChange={props.onChange}
      />
    </LocalizationProvider>
  );
};

export default AptiveDatePicker;

