import { API_URL } from "../constants";
import { apiRequest } from "./apiRequest";

export const getUrl = (params) => `${API_URL}/api/v1/contact/search?${params}`;

const searchLead = async (info) => {
  const params = new URLSearchParams(info);

  return await apiRequest(getUrl(params), "get");
};

export default searchLead;