import styled from "@emotion/styled";

export const NotificationStyles = styled.div`
  .notification-enter {
    opacity: 1;
    transform: scale(0.9);
  }

  .notification-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 500ms,
      transform 500ms;
  }

  .notification-exit {
    opacity: 1;
  }

  .notification-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition:
      opacity 500ms,
      transform 500ms;
  }

  .notification-exit-done {
    opacity: 0;
  }
`;
