import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { formatDate } from "./../../utils/utils";
import { leadAtom, Lead } from "./../../atoms/lead";
import { loadingAtom } from "atoms/loading";

import { DispositionsStyles } from "./Dispositions.Styles";
import createDisposition from "./../../api/createDisposition";
import getDispositionOptions from "./../../api/getDispositionOptions";

import { ButtonPrimary } from "./../Buttons/Button.Styled";
import SectionHeader from "./../SectionHeader/SectionHeader";
import Select from "../Form/Select/Select";

interface Disposition {
  created_at: string;
  created_by_username: string;
  disposition: string;
  system_id: number;
}

interface DispositionOption {
  label: string;
  value: string;
  options?: DispositionOption[];
}

interface DispositionActionsProps {
  newDisposition: string;
  setNewDisposition: any;
  saveDisposition: () => void;
}

export const formatDispositionsForOptions = (
  dispositionList: {
    name: string;
    id: string;
    subDispositions: { name: string; id: string }[];
  }[],
) => {
  const allOptions = [];

  for (const thisDisposition of dispositionList) {
    const newOption: DispositionOption = {
      label: thisDisposition.name,
      value: thisDisposition.id,
    };

    if (thisDisposition.subDispositions) {
      newOption.options = [];
      for (const thisSubDisposition of thisDisposition.subDispositions) {
        newOption.options.push({
          label: thisSubDisposition.name,
          value: thisSubDisposition.id,
        });
      }
    }

    allOptions.push(newOption);
  }

  return allOptions;
};

export const DispositionActions = (
  props: DispositionActionsProps,
): React.JSX.Element => {
  const optionsRequested = useRef(false);

  const [dispositionOptions, setDispositionOptions] = useState<
    DispositionOption[]
  >([]);

  useEffect(() => {
    if (optionsRequested.current) return;

    // Retrieve disposition options
    async function getDispositionOptionsData () {
      const dispositionList = (await getDispositionOptions()) || [];
      setDispositionOptions(formatDispositionsForOptions(dispositionList.data));
    }

    getDispositionOptionsData();
    optionsRequested.current = true;
  }, []);

  return (
    <div className="add-disposition-container">
      <Select
        options={dispositionOptions}
        value={props.newDisposition}
        onChange={props.setNewDisposition}
        placeholder={"Select an option"}
        isGroupable
      />
      <ButtonPrimary onClick={props.saveDisposition}>Submit</ButtonPrimary>
    </div>
  );
};

export const Disposition = (props: {
  disposition: Disposition;
}): React.JSX.Element => (
  <div className="disposition-container" data-testid="disposition-container">
    <div className="disposition-item-icon-container">
      <div className="disposition-item-icon" />
    </div>
    <div className="disposition-body-container">
      <div className="disposition">{props.disposition.disposition}</div>
      <div className="disposition-metadata">
        {formatDate(props.disposition.created_at)}
      </div>
      <div className="disposition-metadata">
        {props.disposition.created_by_username}
      </div>
    </div>
  </div>
);

const Dispositions = (): React.JSX.Element => {
  let { leadId } = useParams();
  const dispContainerRef = useRef<HTMLDivElement>(null);
  const setLoading = useSetRecoilState(loadingAtom);
  const leadData = useRecoilValue(leadAtom);
  const [dispositions, setDispositions] = useState<Disposition[]>([]);
  const [newDisposition, setNewDisposition] = useState<string>("");

  const saveDisposition = async () => {
    if (!newDisposition) return;
    try {
      setLoading(true);
      const response = await createDisposition(
        leadId,
        parseInt(newDisposition),
      );
      const dispositionToAdd: Disposition = response.data;
      setDispositions([dispositionToAdd, ...dispositions]);
      setNewDisposition("");
      if (dispContainerRef && dispContainerRef.current) {
        dispContainerRef.current.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let dispositionHistory = leadData ? (leadData as Lead).dispositions : null;
    setDispositions(dispositionHistory);
  }, [leadData]);

  return (
    <DispositionsStyles
      className="disposition-parent-container"
      data-testid="disposition-parent-container"
    >
      <SectionHeader label="Dispositions" icon="LocationMarkerDark" />

      <div ref={dispContainerRef} className="disposition-list-container">
        {dispositions &&
          dispositions.map((thisDisposition: Disposition, key: number) => (
            <Disposition key={key} disposition={thisDisposition} />
          ))}
      </div>
      <DispositionActions
        saveDisposition={saveDisposition}
        newDisposition={newDisposition}
        setNewDisposition={setNewDisposition}
      />
    </DispositionsStyles>
  );
};

export default Dispositions;
