import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { ConfigCatProvider } from "configcat-react";
import App from "./App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const CONFIGCAT_SDK_KEY = process.env.REACT_APP_CONFIGCAT_SDK_KEY || '';

root.render(
  <ConfigCatProvider sdkKey={CONFIGCAT_SDK_KEY}>
    <RecoilRoot>
      <StrictMode>
        <App />
      </StrictMode>
    </RecoilRoot>
  </ConfigCatProvider>,
);
