import React, { useState, useEffect } from "react";

import { ButtonPrimary, ButtonSecondary } from "./../Buttons/Button.Styled";
import { SearchStyles } from "./Search.Styles";
import { TextStyled } from "./../Text/Text.Styles";
import LeadInfoForm from "./../LeadManagement/LeadMainInfo/LeadInfoForm";
import Spinner from "./../Spinner/Spinner";
import searchLead from "./../../api/searchLead";
import { useNavigate } from "react-router-dom";
import Modal from "./../Modal/Modal";

const SearchFields = [
  {
    component: "phoneInput",
    placeholder: "e.g., 555-867-5309",
    additionalClassName: "_p-right",
    name: "phone_number",
  },
  {
    label: "Email address",
    component: "debounceInput",
    placeholder: "e.g., john.doe@example.com",
    name: "email_address",
    additionalClassName: "_p-left _p-right",

    // eslint-disable-next-line
    validator: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  },
  {
    label: "First name",
    component: "input",
    placeholder: "e.g., John",
    name: "name_first",
    additionalClassName: "_p-left _p-right",
    regexp: /^[A-Za-z]+$/,
  },
  {
    label: "Last name",
    component: "input",
    placeholder: "e.g., Smith",
    name: "name_last",
    additionalClassName: "_p-left",
    regexp: /^[A-Za-z]+$/,
  },
];

interface SearchFields {
  phone_number?: string;
  email_address?: string;
  name_first?: string;
  name_last?: string;
  address_line_1?: string;
}

const fieldsInitialData: SearchFields = {
  phone_number: "",
  email_address: "",
  name_first: "",
  name_last: "",
  address_line_1: "",
};

const Search = (): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SearchFields>(fieldsInitialData);
  const [leads, setLeads] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const search = async (data: SearchFields) => {
    try {
      setLoading(true);
      const req = await searchLead({
        ...data,
        phone_number: data.phone_number?.replace(/\D/g, ""),
      });
      
      setLeads(req.data);
      setError(false);
    } catch (e) {
      console.log(e);
      setError(true);
    }

    setLoading(false);
  };

  const resetSearch = () => {
    setLeads([]);
    setData(fieldsInitialData);
    setError(false);
  };

  useEffect(() => {
    if (isOpen) {
      setData(fieldsInitialData);
    } else {
      resetSearch();
    }
  }, [isOpen]);

  const goToLead = (id: number) => {
    setIsOpen(false);
    resetSearch();
    navigate(`/${id}`);
  };

  return (
    <SearchStyles data-testid="search">
      <ButtonSecondary onClick={() => setIsOpen(true)}>
        Search leads
      </ButtonSecondary>
      {isOpen && (
        <Modal
          className="search-modal"
          header={"Search leads"}
          body={
            <div>
              <div className="search-modal__fields">
                <LeadInfoForm
                  setData={setData}
                  setErrors={() => {}}
                  data={data}
                  errors={[]}
                  formFields={SearchFields}
                />
                <ButtonPrimary onClick={() => search(data)}>
                  Search
                </ButtonPrimary>
              </div>
              <div className="search-modal__table">
                {loading && <Spinner />}
                {leads.length && !error ? (
                  leads.map((l, i) => (
                    <div
                      onClick={() => goToLead(l.system_id)}
                      className="search-modal__table__row"
                      key={i}
                    >
                      <TextStyled textAlign="center" fontWeight="thin">
                        {l.name_first} {l.name_last}
                      </TextStyled>
                      <TextStyled textAlign="center" fontWeight="thin">
                        {l.email_address}
                      </TextStyled>
                      <TextStyled textAlign="center" fontWeight="thin">
                        {l.phone_number}
                      </TextStyled>
                      <TextStyled textAlign="center" fontWeight="thin">
                        {l.address_line_1}
                      </TextStyled>
                      <TextStyled textAlign="center" fontWeight="thin">
                        {l.parent_id === null ? "(P)" : "(C)"}
                      </TextStyled>
                    </div>
                  ))
                ) : (
                  <TextStyled
                    className="search-modal__table__no-leads"
                    fontWeight="thin"
                  >
                    {error ? "Error occured" : "No leads found"}
                  </TextStyled>
                )}
              </div>
            </div>
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </SearchStyles>
  );
};

export default Search;
