import * as React from "react";

import { MenuRadioItemStyle, MenuRadioButton, RefreshIconStyle, MenuItemStyle } from "./MenuRadioItem.Style";
import { FlexContainer } from "./../Flex/Flex.Styles";
import { TextStyled } from "./../Text/Text.Styles";
import {
  graceGray600,
  gray400,
  bluePrimary,
  baseGray200,
} from "./../../styleConstants";
import { getSyncData } from "api/getSyncData";
import { Lead } from "atoms/lead";
import { useRecoilState } from "recoil";
import { loadingAtom } from "atoms/loading";
import Spinner from "components/Spinner/Spinner";

interface MenuRadioItemProps {
  label: string;
  isCompleted?: boolean;
  isActive?: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  eventId?: string;
  setData: React.Dispatch<React.SetStateAction<Lead>>;
  setApiError: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveRequestText: React.Dispatch<React.SetStateAction<string>>;
}

const MenuRadioItem = (props: MenuRadioItemProps): React.JSX.Element => {
  const onClick = () => {
    !props.isDisabled && !props.isActive && props.onClick();
  };

  const [loading, setLoading] = useRecoilState(loadingAtom);

  const determineType = (label: string): string => {
    const lowerLabel = label.toLowerCase();
    if (lowerLabel.includes("person")) return "customer";
    if (lowerLabel.includes("plan")) return "subscription";
    if (lowerLabel.includes("schedule")) return "appointment";
    if (lowerLabel.includes("billing")) return "payment";
    return "";
  };

  const type = determineType(props.label);
  async function getSync () {
    setLoading(true);
    try {
      const syncRes = await getSyncData(props.eventId, type);
      props.setData({ ...syncRes.data });
    } catch (e) {
      props.setApiError(true);
      props.setSaveRequestText("Data sync error");
      console.log(e);
    }

    setLoading(false);
  }

  const handleRefreshClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); 
    if (props.eventId) {
      getSync();
    }
  };



  return (
      <MenuRadioItemStyle onClick={onClick} data-testid="menu-radio-item">
        {loading && <Spinner overlay={false} />}
        <FlexContainer style={{justifyContent:"space-between", width: "100%"}}>
          <MenuItemStyle>
            <MenuRadioButton
              isActive={props.isActive}
              isCompleted={props.isCompleted}
            >
              <div className="menu-ralio-item__inner"></div>
              <div className="menu-ralio-item__check"></div>
            </MenuRadioButton>
            <FlexContainer column alignItems="start">
              <TextStyled
                fontSize="md"
                fontWeight="thin"
                color={
                  props.isActive
                    ? bluePrimary
                    : props.isDisabled
                    ? baseGray200
                    : graceGray600
                }
              >
                {props.label}
              </TextStyled>
              <TextStyled
                fontWeight="thin"
                color={props.isDisabled ? baseGray200 : gray400}
              >
                {props.isCompleted ? "Complete" : "Incomplete"}
              </TextStyled>
            </FlexContainer>
          </MenuItemStyle>
          <RefreshIconStyle onClick={handleRefreshClick}>
            <div style={{width: "20px"}}>
              <img src='../images/RefreshIcon.svg' />
            </div>
          </RefreshIconStyle>
        </FlexContainer>
      </MenuRadioItemStyle>
  );
};

export default MenuRadioItem;
