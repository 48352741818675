import { atom } from "recoil";

export interface Plan {
  addons: any;
  agreementLengths: any;
  name: string;
  prices: any;
  serviceTypeId: number;
  isMonthlyBilling: boolean;
}

const plansAtom = atom({
  key: "plans",
  default: {}
});

export { plansAtom };
