import styled from "@emotion/styled";
import {
  baseGray200,
  bluePrimary,
  graceGray900,
  placeholderColor,
  uiTextError,
  uiGray50,
  basePageHoverBg,
} from "styleConstants";

interface FormFieldProps {
  required?: boolean;
  isInvalid?: boolean;
  row?: boolean;
  divider?: boolean;
}

interface Props {
  text?: string;
}

export const ValidityIconWrapper = styled.div<Props>`
  margin-left: 4px;
  padding-bottom: 2px;
  cursor: pointer;
  position: relative;

  div:nth-of-type(2) {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background: #f5f1f1;
    padding: 5px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    top: -30px;
    left: -34px;
    white-space: nowrap;
  }

  &:hover {
    div:nth-of-type(2) {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const ValidityIcon = styled.div<Props>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 4px;
`;

export const FormField = styled.div<FormFieldProps>`
  display: flex;  
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  margin-bottom: ${(props) => (props.row ? "10px" : "")};  
  
  &.zip-input {
    div {
      display: flex;
      align-items: center;
    }
  }

  &.price-input {
    position: relative;

    &::before {
      content: "$";
      position: absolute;
      bottom: 9px;
      left: 13px;
    }

    &::after {
      content: "USD";
      position: absolute;
      bottom: 9px;
      right: 13px;
    }

    input {
      padding-left: 30px;
      padding-right: 55px;
    }
  }

  .date-input {
    max-height: 40px;
    height: 40px;

    .select__field {
      z-index: 1;

      &::after {
        opacity: 0;
      }
    }

    .select__menu__wrapper {
      z-index: 3;
    }

    &__actions {
      position: relative;
      top: -98%;
      width: fit-content;
      left: calc(100% - 82px);
      padding: 9px 16px;
      background: ${uiGray50};
      border-left: 1px solid ${baseGray200};
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      display: flex;

      & > div {
        width: 20px;
        height: 20px;
      }

      &__cancel {
        background: url(../images/Cross.svg);
        margin-right: 8px;
        cursor: pointer;
        z-index: 2;
      }

      &__icon {
        background: url(../images/Calendar.svg);
      }
    }
  }

  p {
    display: flex;

    &::after {
      content: "*";
      color: ${uiTextError};
      display: ${(props) => (props.required ? "block" : "none")};
      margin-left: 4px;
    }
  }

  .tag-input {
    display: flex;
    flex-direction: row;

    &__tag {
      background: ${basePageHoverBg};
      padding: 2px 10px;
      margin-right: 8px;
      font-size: 12px;
      border-radius: 6px;
      display: flex;

      &__cancel {
        margin-left: 4px;
      }
    }

    input {
      border: none !important;
      padding: 0;
    }
  }

  input,
  textarea,
  .tag-input,
  .add-note-input__wrapper {
    margin-right: ${(props) => (props.row ? "16px" : "")};
    
    border-radius: 6px;
    padding: 10px 13px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border: 1px solid
      ${(props) => (props.isInvalid ? uiTextError : baseGray200)};
    color: ${(props) => (props.isInvalid ? uiTextError : graceGray900)};
    transition: all ease-in-out 0.2s;

    &:invalid {
      border-color: ${uiTextError};
    }

    &::placeholder {
      color: ${placeholderColor};
    }

    &,
    &:focus,
    &:focus-visible {
      outline: none;
    }

    &:focus,
    &._focused {
      border-color: ${(props) => (props.isInvalid ? uiTextError : bluePrimary)};
    }

    &._small-field {
      flex-basis: 25%;
      max-width: 50px;
    }
  }
`;
