import styled from "@emotion/styled";

export const CreateLeadStyles = styled.div`
  .create-modal {
    &__fields {
      display: flex;
      align-items: center;

      button {
        flex-grow: 1;
        margin-left: 8px;
      }
    }

    .info-form {
      max-width: 100%;

      &__form-group {
        max-width: 100%;
      }
    }

    &__close {
      cursor: pointer;
      content: url(../images/X.svg);
      width: 24px;
      height: 24px;
    }
  }
`;
