import styled from "@emotion/styled";
import { baseWhite, graceGray50, gray200, green100, } from "styleConstants";


export const TableStyled = styled.div`     
    .rounded-corners {    
      border-radius: 10px;   
      border-spacing: 0;
      border-collapse: separate;
      border: var(--border);
      overflow: hidden;
     }
     
     .rounded-corners th:not(:last-child),
     .rounded-corners td:not(:last-child) {
      border-right: var(--border);
     }
     
     .rounded-corners>thead>tr:not(:last-child)>th,
     .rounded-corners>thead>tr:not(:last-child)>td,
     .rounded-corners>tbody>tr:not(:last-child)>th,
     .rounded-corners>tbody>tr:not(:last-child)>td,
     .rounded-corners>tfoot>tr:not(:last-child)>th,
     .rounded-corners>tfoot>tr:not(:last-child)>td,
     .rounded-corners>tr:not(:last-child)>td,
     .rounded-corners>tr:not(:last-child)>th,
     .rounded-corners>thead:not(:last-child),
     .rounded-corners>tbody:not(:last-child),
     .rounded-corners>tfoot:not(:last-child) {
      border-bottom: var(--border);
     }
    
    .table {    
      border-collapse: collapse;    
      width: 540px;            
    }
    
    .header, .header-button {
      background-color: #78856E;
      color: ${baseWhite};
      padding: 10px;
    }

    .header-button {
      cursor: pointer;
    }
    
    .row:nth-of-type(odd) {
      background-color: ${graceGray50};
    }
  
    .row:nth-of-type(even) {
      background-color: ${gray200};
    }
    
    .row:hover {
      background-color: ${green100};
    }
    
    .cell {
      padding: 8px;
      border: 1px solid #ddd;
      text-align: center;
    }  
`;
