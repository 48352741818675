import React from "react";
import { useRecoilState } from "recoil";

import { ErrorBannerStyled } from "./ErrorBanner.Styles";
import {
  ModalCross,
} from "./../Modal/Modal.Styles";

import { errorBannerAtom } from "./../../atoms/errorBanner";


const ErrorBanner = (): React.JSX.Element => {
  const [errorBanner, setErrorBanner]: any = useRecoilState(errorBannerAtom);  

  const handleClick = () => {
    setErrorBanner({
      open: false,
      message: ''
    });
  };
    
  return errorBanner.open ? (
    <ErrorBannerStyled data-testid="error-banner">            
      <img className='error-banner-icon' src={`../images/ExclamationCircle.svg`} />
      {errorBanner.message}      
      <ModalCross className="modal__cross" onClick={handleClick} />
      
    </ErrorBannerStyled> 
  ) : (
    <></>
  );
  
};

export default ErrorBanner;
