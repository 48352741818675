import * as React from "react";
import { SpinnerStyles } from "./Spinner.Styles";

interface SpinnerProps {
  overlay?: boolean;
}

const Spinner = (props: SpinnerProps): React.JSX.Element => {
  return (
    <SpinnerStyles data-testid="spinner">
      {props.overlay && <div className="overlay"></div>}
      <div className="spinner"></div>
    </SpinnerStyles>
  );
};

export default Spinner;
