import styled from "@emotion/styled";
import { baseGray200, baseWhite, bluePrimary } from "styleConstants";

export const LeadManagementMenuStyled = styled.div`
  padding: 16px 0;
  border-right: 1px solid ${baseGray200};
  height: 100%;
  box-sizing: border-box;
  background: ${baseWhite};

  .left-menu {
    &__radio-wrapper {
      height: 264px;
    }

    &__item {
      border-top: 1px solid ${baseGray200};
      padding: 25px 0;
      cursor: pointer;
      margin: 0 22px;

      &._is-active {
        p {
          color: ${bluePrimary};
        }
      }
    }
  }
`;
